import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setHeliportsList } from "../../store/heliports/data/action";
import { getRegion } from "../../store/root/reducer";
import { getAllHeliports } from "../api/api";
import CustomAppBar from "../layout/CustomAppBar";
import DeckMap from "../layout/DeckMap";
import PermanentDrawerLeft from "../layout/PermanentDrawerLeft";

export default function HomePage({ handleLogout }: any) {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const currentRegion = useSelector(getRegion);

  const logout = () => {
    handleLogout();
    navigate("/", { replace: true });
  };

  useEffect(() => {
    getAllHeliports(currentRegion)
      .then((x) => dispatch(setHeliportsList(x)))
      .catch((e) => console.error("error ", e));
  }, []);

  return (
    <div className="flex flex-col flex-1">
      <div className="flex w-full z-1 bg-primary">
        <CustomAppBar handleLogout={logout} />
      </div>
      <div className="flex h-full w-full">
        <PermanentDrawerLeft />
        <DeckMap />
      </div>
    </div>
  );
}
