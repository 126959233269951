import React from "react";
import CustomTable from "../base/CustomTable";
import CustomAppBar from "../layout/CustomAppBar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentHeliports } from "../../store/heliports/data/action";
import { setPage } from "../../store/appInfo/data/action";

const rows = [
  {
    id: 3122631,
    nome: "Eliporto 1",
    stato: "Funzionante",
    posizione: "Ancona",
  },
  {
    id: 3122632,
    nome: "Eliporto 2",
    stato: "Funzionante",
    posizione: "Ancona",
  },
  {
    id: 3122633,
    nome: "Eliporto 3",
    stato: "Funzionante",
    posizione: "Ancona",
  },
  {
    id: 3122634,
    nome: "Eliporto 4",
    stato: "Funzionante",
    posizione: "Ancona",
  },
  {
    id: 3122635,
    nome: "Eliporto 5",
    stato: "Funzionante",
    posizione: "Ancona",
  },
  {
    id: 3122636,
    nome: "Eliporto 6",
    stato: "Funzionante",
    posizione: "Ancona",
  },
];

const headers = ["ID", "Nome", "Stato", "Posizione"];

export default function DevicesPage({ handleLogout }: any) {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const onClick = (value: any) => {
    dispatch(setCurrentHeliports(value));
    dispatch(setPage("/dashboard/" + value.city));
    navigate("/dashboard/" + value.city, { replace: false });
  };

  const logout = () => {
    handleLogout();
    navigate("/", { replace: true });
  };

  return (
    <div className="relative flex flex-col h-full w-full">
      <div className="flex w-full h-1-10 z-1 bg-primary">
        <CustomAppBar handleLogout={logout} />
      </div>
      <div className="relative flex h-9-10 w-full flex-col bg-primary">
        <CustomTable headers={headers} rows={rows} onClick={onClick} />
      </div>
    </div>
  );
}
