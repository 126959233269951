import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { from, combineLatest, of, Subscription } from "rxjs";
import { catchError, tap, take } from "rxjs/operators";
import {
  setCurrentHeliports,
  setHeliportsList,
} from "../../store/heliports/data/action";
import { HeliportsModel } from "../../store/heliports/data/model";
import { getCurrentHeliports, getRegion } from "../../store/root/reducer";
import {
  getAllHeliports,
  getDetailHeliport,
  getPhotosHeliport,
} from "../api/api";
import MediaCard from "../base/MediaCard";

export default function PhotosDetailPage() {
  let subs: Subscription;
  let dispatch = useDispatch();
  let location = useLocation();
  const [list, setList] = useState<Array<any>>([]);
  const heliportCurrent = useSelector(getCurrentHeliports);
  const currentRegion = useSelector(getRegion);

  const getPhoto = (city: string) => {
    let tmpList: any[] = [];
    subs = combineLatest([
      from(getPhotosHeliport(city, 1)),
      from(getPhotosHeliport(city, 2)),
      from(getPhotosHeliport(city, 3)),
      from(getPhotosHeliport(city, 4)),
      from(getPhotosHeliport(city, 5)),
      from(getPhotosHeliport(city, 6)),
    ])
      .pipe(
        take(1),
        tap((x) => {
          x.forEach((element, index) => {
            tmpList.push({
              photo: element,
              index,
            });
          });
          setList(tmpList.sort((a, b) => a.index - b.index));
        }),
        catchError((e) => of(e))
      )
      .subscribe();
  };

  useEffect(
    () => () => {
      if (subs) subs.unsubscribe();
    },
    []
  );

  useEffect(() => {
    if (heliportCurrent) {
      getPhoto(heliportCurrent.city);
    } else {
      getAllHeliports(currentRegion)
        .then((x) => dispatch(setHeliportsList(x)))
        .catch((e) => console.error("error ", e));
      let arrLoc = location.pathname.split("/");
      if (arrLoc.length === 4) {
        getDetailHeliport(arrLoc[3])
          .then((x) => {
            dispatch(setCurrentHeliports(x));
          })
          .catch((e) => console.error("error ", e));
        getPhoto(arrLoc[3]);
      }
    }
  }, []);

  return (
    <div className="flex w-full bg-primary h-full align-center pt-16 wrap justify-evenly overflow-auto">
      {list.map((card: HeliportsModel, index: number) => (
        <MediaCard key={index} card={card} disableClick />
      ))}
    </div>
  );
}
