import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setRegion } from "../../store/appInfo/data/action";
import MediaCard from "../base/MediaCard";
import Typography from "@mui/material/Typography";
import { saveLog } from "../api/api";

const arrCard = [
  {
    name: "Marche",
    photo: process.env.PUBLIC_URL + "/assets/marche.png",
  },
  {
    name: "Abruzzo",
    photo: process.env.PUBLIC_URL + "/assets/abruzzo.png",
  },
  {
    name: "Emilia Romagna",
    photo: process.env.PUBLIC_URL + "/assets/emilia.png",
  },
];

export default function RegionPage() {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const handleChangeRegion = (newValue: any) => {
    if (newValue) {
      saveLog(
        "Caricati dati generali regione " + newValue.name + " su sito web"
      )
        .then()
        .catch((e) => console.error("error ", e));
      dispatch(setRegion(newValue.name.toLowerCase()));
      navigate("/home", { replace: false });
    }
  };

  return (
    <div className="flex bg-primary flex-col w-full h-full">
      <Typography
        variant="h3"
        component="div"
        sx={{
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "32px",
          marginTop: "50px",
        }}
      >
        Seleziona regione
      </Typography>
      <div className="flex w-full h-full align-center justify-center wrap">
        {arrCard.map((card: any, index: number) => (
          <MediaCard
            sxCard={{
              height: 250,
              width: 150,
              minHeight: 200,
              minWidth: 150,
            }}
            sxImg={{
              height: "75%",
            }}
            sxName={{
              justifyContent: "center",
            }}
            key={index}
            card={card}
            onClickImage
            onClick={handleChangeRegion}
          />
        ))}
      </div>
    </div>
  );
}
