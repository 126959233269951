/* eslint-disable */
import { CompositeLayer } from '@deck.gl/core';
import { IconLayer } from '@deck.gl/layers';
import Supercluster from 'supercluster';

function getIconName(size: number) {
  if (size === 0) {
    return '';
  }
  if (size < 10) {
    return `marker-${size}`;
  }
  if (size < 300) {
    return `marker-${Math.floor(size / 10)}0`;
  }
  return 'marker-300';
}

function getIconSize(size: number) {
  return Math.min(100, size) / 100;
}

class IconClusterLayer extends CompositeLayer<any, any> {
  shouldUpdateState({ changeFlags }: any) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }: any) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({
        radius: props.sizeScale,
        maxZoom: props.maxClusteringZoom,
      });
      index.load(
        props.data.map((d: any) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d,
        }))
      );
      this.setState({ index });
    }

    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }: { info: any; mode: string }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== 'hover') {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f: any) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    return [
      new IconLayer(
        this.getSubLayerProps({
          ...this.props,
          data,
          getIcon: (d: any) =>
            getIconName(d.properties.cluster ? d.properties.point_count : 1),
          getPosition: (d: any) => d.geometry.coordinates,
          getSize: (d: any) =>
            getIconSize(d.properties.cluster ? d.properties.point_count : 1),
        })
      ),
    ];
  }
}

IconClusterLayer.layerName = 'IconClusterLayer';

export default IconClusterLayer;
