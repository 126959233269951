interface Config {
  name: string;
  version: string;
  url: string;
  apiUrl: string;
  maintenance: { message: string; visible: boolean };
}
export default class Environment {
  public static config: Config;
}
