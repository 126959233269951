import {
  AppInfoDataActionTypes,
  AppInfoDataState,
  SET_USERNAME,
  SET_PAGE,
  SET_REGION,
} from "./types";

const initialState: AppInfoDataState = {
  username: null,
  page: { page: "/home", section: null },
  region: "marche",
};

export default function appInfoReducer(
  state = initialState,
  action: AppInfoDataActionTypes
): AppInfoDataState {
  switch (action.type) {
    case SET_USERNAME:
      return {
        ...state,
        username: action.payload,
      };
    case SET_PAGE:
      return {
        ...state,
        page: {
          page: action.payload.page,
          section: action.payload.section ? action.payload.section : null,
        },
      };
    case SET_REGION:
      return {
        ...state,
        region: action.payload,
      };
    default:
      return state;
  }
}

// SELECTORS
export const getUsername = (state: AppInfoDataState) => state.username;
export const getPage = (state: AppInfoDataState) => state.page;
export const getRegion = (state: AppInfoDataState) => state.region;
