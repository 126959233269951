import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setUsername } from "../../store/appInfo/data/action";
import { useDispatch } from "react-redux";
import { authenticate } from "../api/api";
import { useAlert } from "react-alert";
import CookieConsent, { Cookies } from "react-cookie-consent";
import Environment from "../../environment";
import Alert from "@mui/material/Alert/Alert";
import AlertTitle from "@mui/material/AlertTitle/AlertTitle";
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const theme = createTheme();

export default function LoginPage({ setUser }: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [openPopup, setOpenPopup] = useState<boolean>(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let username: string = data.get("username")?.toString() || "";
    let password: string = data.get("password")?.toString() || "";
    authenticate({
      username,
      password,
    })
      .then((x) => {
        if (x?.status === "success") {
          localStorage.setItem("token", x.token);
          setUser(username);
          dispatch(setUsername(username || ""));
          navigate("region");
        }
      })
      .catch((e) => {
        console.error("error ", e);
        let err = "Errore momentaneo";
        if (e.toString().includes("401")) {
          err = "Utente o password errata";
        }
        alert.show(err);
      });
  };

  return (
    <div className="flex h-full w-full bg-login">
      {Environment.config.maintenance.visible && openPopup && (
        <Alert
          severity="warning"
          sx={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 1000,
            width: 550,
            height: 200,
            marginTop: "-100px",
            marginLeft: "-275px",
          }}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenPopup(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>Attenzione</AlertTitle>
          {Environment.config.maintenance.message}
        </Alert>
      )}
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
                sx={{ backgroundColor: "#FFFFFF" }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                sx={{ backgroundColor: "#FFFFFF" }}
              />
              <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
            </Box>
          </Box>
        </Container>
        <CookieConsent
          location="bottom"
          buttonText="OK"
          cookieName="securflyCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{
            color: "#4e503b",
            fontSize: "13px",
            borderRadius: "25%",
          }}
        >
          Questo sito utilizza i cookie e tecnologie simili per migliorare e
          personalizzare la tua esperienza. Se vuoi saperne di più consulta la
          nostra{" "}
          <span
            onClick={(e: any) => {
              window.open(
                process.env.PUBLIC_URL + "/assets/Privacy Policy.pdf",
                "_blank"
              );
            }}
            className="cookieBtn"
            style={{
              fontWeight: "bold",
              textDecorationLine: "undeline",
              cursor: "pointer",
              color: "#FFFFFF",
            }}
          >
            Privacy Policy
          </span>
          .
        </CookieConsent>
      </ThemeProvider>
    </div>
  );
}
