import React from "react";

interface MapButtonProps {
  icon?: any;
  onClick?: () => void;
}

export default function MapButton({ icon, onClick }: MapButtonProps) {
  return (
    <div className="mapButton" onClick={onClick}>
      {icon ? icon : ""}
    </div>
  );
}
