import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CircleIcon from "@mui/icons-material/Circle";
import { setPage } from "../../store/appInfo/data/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { setCurrentHeliports } from "../../store/heliports/data/action";

export default function BasicMenu({ elements, label, iconItem }: any) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item: any) => {
    if (label === "Heliports") {
      dispatch(setCurrentHeliports(item));
      dispatch(setPage("/dashboard/" + item.city));
      navigate("/dashboard/" + item.city, { replace: false });
    } else if (label === "Archive") {
      dispatch(setPage(item.value, item.label));
      navigate(item.value, { replace: false });
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id={"basic-button" + label}
        aria-controls={open ? "basic-menu" + label : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        color="inherit"
        sx={{ textTransform: "capitalize", fontSize: "medium" }}
      >
        {label}
      </Button>
      <Menu
        id={"basic-menu" + label}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button" + label,
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#222",
            color: "#FFFFFF",
          },
        }}
      >
        {elements.map((x: any, index: number, arr: any[]) => (
          <div key={index}>
            <MenuItem
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                padding: "4px 8px",
                minWidth: "120px",
              }}
              key={index}
              onClick={() => handleSelect(x)}
            >
              <div>{x.label ? x.label : x.name}</div>
              {iconItem && <CircleIcon sx={{ color: x.statusColor }} />}
            </MenuItem>
            {index !== arr.length - 1 && <Divider />}
          </div>
        ))}
      </Menu>
    </div>
  );
}
