import data, * as appInfoDataReducer from "./data/reducer";
import { combineReducers } from "redux";
import { AppInfoState } from "./types";

export default combineReducers({
  data,
});

//DATA
export const getUsername = (state: AppInfoState) =>
  appInfoDataReducer.getUsername(state.data);
export const getPage = (state: AppInfoState) =>
  appInfoDataReducer.getPage(state.data);
export const getRegion = (state: AppInfoState) =>
  appInfoDataReducer.getRegion(state.data);
