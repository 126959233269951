import React, { useMemo, useRef, useState } from "react";
import DeckGL from "@deck.gl/react";
import { IconLayer, WebMercatorViewport } from "deck.gl";
import InteractiveMap, { Popup, _MapContext } from "react-map-gl";
import IconClusterLayer from "../layer/icon-cluster-layer";
import iconMapping from "../assets/cluster-atlas-mapping.json";
import MapButton from "../base/MapButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//ICON
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { setPage } from "../../store/appInfo/data/action";
import { setCurrentHeliports } from "../../store/heliports/data/action";
import { getHeliportsList, getRegion } from "../../store/root/reducer";

interface DeckViewState {
  longitude: number;
  latitude: number;
  zoom: number;
  pitch: number;
  bearing: number;
  bbox: Array<number>;
  height: number;
  width: number;
}

export default function DeckMap() {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const heliportsList = useSelector(getHeliportsList);
  const region = useSelector(getRegion);
  const [tooltip, setTooltip] = useState<any>(null);
  const mapRef = useRef<any>();
  const ref = useRef(null);

  let bboxCenterMap = useMemo(() => {
    let optionsMap = null;
    switch (region) {
      case "abruzzo":
        optionsMap = {
          bbox: [
            12.526654570630832, 41.55927796788175, 14.93815359406824,
            42.96779884665513,
          ],
          center: {
            latitude: 42.26747216473812,
            longitude: 13.732404082349726,
          },
        };
        break;

      case "emilia romagna":
        optionsMap = {
          bbox: [
            9.963460872757647, 43.534587996511455, 13.506204803060392,
            45.049786838469345,
          ],
          center: {
            latitude: 44.29707444748697,
            longitude: 11.734832837908815,
          },
        };
        break;

      case "marche":
      default:
        optionsMap = {
          bbox: [
            12.53710299091952, 43.12660791125923, 12.827260219542566,
            43.264770503913894,
          ],
          center: {
            latitude: 43.31550625702479,
            longitude: 12.682181605231133,
          },
        };
        break;
    }
    return optionsMap;
  }, [region]);

  const [viewState, setViewState] = useState<DeckViewState>({
    longitude: bboxCenterMap.center.longitude,
    latitude: bboxCenterMap.center.latitude,
    zoom: 8,
    pitch: 0,
    bearing: 0,
    bbox: bboxCenterMap.bbox,
    height: 0,
    width: 0,
  });

  function onLoad() {
    if (mapRef.current) {
      /* mapRef.current.setLayoutProperty("country_1", "text-field", [
        "get",
        "name_it",
      ]);
      mapRef.current.setLayoutProperty("country_2", "text-field", [
        "get",
        "name_it",
      ]);
      mapRef.current.setLayoutProperty("country_3", "text-field", [
        "get",
        "name_it",
      ]);
      mapRef.current.setLayoutProperty("state", "text-field", [
        "get",
        "name_it",
       ]);*/
    }
  }

  function zmIn() {
    if (viewState.zoom + 1 < 18) {
      let newViewState: DeckViewState = {
        ...viewState,
        longitude: viewState.longitude,
        latitude: viewState.latitude,
        zoom: viewState.zoom + 1,
        pitch: viewState.pitch,
        bearing: viewState.bearing,
        height: viewState.height,
        width: viewState.width,
        bbox: viewState.bbox,
      };

      const viewport = new WebMercatorViewport(newViewState);
      const nw = viewport.unproject([0, 0]);
      const se = viewport.unproject([viewport.width, viewport.height]);

      const newBbox: Array<number> = [nw[0], se[1], se[0], nw[1]];

      setViewState({
        ...newViewState,
        bbox: newBbox,
      });
    }
  }

  function zmOut() {
    if (viewState.zoom - 1 > 5) {
      let newViewState: DeckViewState = {
        ...viewState,
        longitude: viewState.longitude,
        latitude: viewState.latitude,
        zoom: viewState.zoom - 1,
        pitch: viewState.pitch,
        bearing: viewState.bearing,
        height: viewState.height,
        width: viewState.width,
        bbox: viewState.bbox,
      };

      const viewport = new WebMercatorViewport(newViewState);
      const nw = viewport.unproject([0, 0]);
      const se = viewport.unproject([viewport.width, viewport.height]);

      const newBbox: Array<number> = [nw[0], se[1], se[0], nw[1]];

      setViewState({
        ...newViewState,
        bbox: newBbox,
      });
    }
  }

  function changeViewState(state: any) {
    const viewport = new WebMercatorViewport(state);
    const nw = viewport.unproject([0, 0]);
    const se = viewport.unproject([viewport.width, viewport.height]);
    const newBbox: Array<number> = [nw[0], se[1], se[0], nw[1]];
    let newState: DeckViewState = {
      ...viewState,
      latitude: state.latitude,
      longitude: state.longitude,
      zoom: state.zoom,
      pitch: state.pitch,
      bearing: state.bearing,
      bbox: newBbox,
      height: state.height,
      width: state.width,
    };

    setViewState(newState);
  }

  const devices =
    viewState.zoom < 7
      ? new IconClusterLayer({
          id: "Device Cluster",
          data: heliportsList,
          visible: true,
          pickable: true,
          getPosition: (d: any) => [d.longitude, d.latitude],
          iconAtlas: process.env.PUBLIC_URL + "/assets/cluster-atlas.png",
          iconMapping,
          sizeMinPixels: 45,
          sizeMaxPixels: 60,
          sizeScale: 100,
          maxClusteringZoom: 14,
        })
      : new IconLayer({
          id: "Device",
          data: heliportsList,
          arrayVisible: true,
          pickable: true,
          stroked: false,
          filled: true,
          extruded: true,
          getFillColor: [154, 201, 255, 255],
          getRadius: 40,
          getElevation: 0,
          visible: true,
          getIcon: (d: any) => {
            return {
              crossOrigin: "anonymous",
              url: process.env.PUBLIC_URL + "/assets/pin.svg",
              width: 100,
              height: 100,
            };
          },
          loadOptions: {
            imagebitmap: {
              resizeWidth: 100,
              resizeHeight: 100,
            },
          },
          getPosition: (d: any) => [d.longitude, d.latitude],
          getSize: (d: any) => 50,
          onClick: (info: any) => {
            setTooltip(info.object || null);
          },
        });

  let layers: Array<any> = [devices];
  function handleClosePopup() {
    setTooltip(null);
  }

  const renderPopup = () => {
    return (
      <Popup
        closeButton={false}
        tipSize={0}
        longitude={tooltip?.longitude || 0}
        latitude={tooltip?.latitude || 0}
      >
        <div>
          <div
            className="flex w-full justify-end items-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              handleClosePopup();
            }}
          >
            <CloseIcon />
          </div>
          <div className="flex flex-col w-full">
            <div className="items-center flex">
              {tooltip.name}: {tooltip.coords}
            </div>
            <div
              className="justify-end items-center flex w-full cursor-pointer"
              onClick={() => {
                dispatch(setCurrentHeliports(tooltip));
                dispatch(setPage("/dashboard/" + tooltip.city));
                navigate("/dashboard/" + tooltip.city, { replace: false });
              }}
            >
              <ArrowCircleRightIcon />
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  return (
    <DeckGL
      id="map"
      ref={ref}
      initialViewState={viewState}
      viewState={viewState}
      onViewStateChange={(e) => changeViewState(e.viewState)}
      layers={layers}
      controller={true}
      ContextProvider={_MapContext.Provider}
      getCursor={({ isDragging, isHovering }) =>
        isDragging ? "grabbing" : isHovering ? "pointer" : "grab"
      }
    >
      <InteractiveMap
        ref={(ref: any) => (mapRef.current = ref && ref.getMap())}
        onLoad={onLoad}
        mapboxApiAccessToken="pk.eyJ1IjoicG1idGVjbm8iLCJhIjoiY2xhMDIycTcyMGp3dzNxbnpwanRpbWwzZyJ9.TLFZV83Yp7IS7k3pZIudOA"
        mapStyle={"mapbox://styles/mapbox/streets-v11"}
        maxZoom={18}
        minZoom={5}
      />
      {tooltip && renderPopup()}
      <div className="flex absolute bottom-16 right-16 cursor-pointer">
        <MapButton onClick={zmIn} icon={<AddIcon />} />
        <MapButton onClick={zmOut} icon={<RemoveIcon />} />
      </div>
    </DeckGL>
  );
}
