import { HeliportsModel } from "./model";

export const SET_HELIPORTS_LIST = "HELIPORTS/DATA/SET_HELIPORTS_LIST";
export const SET_CURRENT_HELIPORT = "HELIPORTS/DATA/SET_CURRENT_HELIPORT";

export type HeliportsDataState = {
  list: Array<HeliportsModel>;
  current: HeliportsModel | null;
};

interface SetHeliportsList {
  type: typeof SET_HELIPORTS_LIST;
  payload: Array<HeliportsModel>;
}

interface SetCurrentHeliport {
  type: typeof SET_CURRENT_HELIPORT;
  payload: HeliportsModel | null;
}

export type HeliportsDataActionTypes = SetHeliportsList | SetCurrentHeliport;
