import { HeliportsModel } from "./model";
import {
  HeliportsDataActionTypes,
  SET_CURRENT_HELIPORT,
  SET_HELIPORTS_LIST,
} from "./types";

export function setHeliportsList(
  list: Array<HeliportsModel>
): HeliportsDataActionTypes {
  return {
    type: SET_HELIPORTS_LIST,
    payload: list,
  };
}

export function setCurrentHeliports(
  current: HeliportsModel | null
): HeliportsDataActionTypes {
  return {
    type: SET_CURRENT_HELIPORT,
    payload: current,
  };
}
