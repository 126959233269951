import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomAppBar from "../layout/CustomAppBar";
import PhotosDetailPage from "../layout/PhotosDetailPage";

export default function ArchivePhotoDetailPage({ handleLogout }: any) {
  let navigate = useNavigate();

  const logout = () => {
    handleLogout();
    navigate("/", { replace: true });
  };

  return (
    <div className="relative flex flex-col h-full w-full">
      <div className="flex h-1-10 w-full z-1 bg-primary">
        <CustomAppBar handleLogout={logout} />
      </div>
      <Typography
        variant="h3"
        component="div"
        sx={{
          justifyContent: "flex-start",
          padding: "4px",
          width: "100%",
          color: "white",
        }}
        className="bg-primary h-1-10 flex align-center"
      >
        {"Photo Detail"}
      </Typography>
      <Divider />
      <div className="relative flex h-8-10 w-full flex-col bg-primary">
        <PhotosDetailPage />
      </div>
    </div>
  );
}
