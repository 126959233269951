import {
  HeliportsDataActionTypes,
  HeliportsDataState,
  SET_CURRENT_HELIPORT,
  SET_HELIPORTS_LIST,
} from "./types";

const initialState: HeliportsDataState = {
  list: [],
  current: null,
};

export default function heliportsReducer(
  state = initialState,
  action: HeliportsDataActionTypes
): HeliportsDataState {
  switch (action.type) {
    case SET_HELIPORTS_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case SET_CURRENT_HELIPORT:
      return {
        ...state,
        current: action.payload,
      };
    default:
      return state;
  }
}

// SELECTORS
export const getList = (state: HeliportsDataState) => state.list;
export const getCurrent = (state: HeliportsDataState) => state.current;
