import data, * as heliportsDataReducer from "./data/reducer";
import { combineReducers } from "redux";
import { HeliportsState } from "./types";

export default combineReducers({
  data,
});

//DATA
export const getListHeliports = (state: HeliportsState) =>
  heliportsDataReducer.getList(state.data);
export const getCurrentHeliports = (state: HeliportsState) =>
  heliportsDataReducer.getCurrent(state.data);
