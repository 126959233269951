import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPage } from "../../store/appInfo/data/action";
import { from, combineLatest, of, Subscription } from "rxjs";
import { catchError, tap, take } from "rxjs/operators";
import {
  setCurrentHeliports,
  setHeliportsList,
} from "../../store/heliports/data/action";
import { HeliportsModel } from "../../store/heliports/data/model";
import { getHeliportsList, getRegion } from "../../store/root/reducer";
import { getAllHeliports, getPhotosHeliport } from "../api/api";
import MediaCard from "../base/MediaCard";

export default function DataArchive() {
  let subs: Subscription;
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const currentRegion = useSelector(getRegion);

  const onClick = (value: HeliportsModel) => {
    dispatch(setCurrentHeliports(value));
    dispatch(setPage("/archive/data/" + value.city, "Data Archive Detail"));
    navigate("/archive/data/" + value.city, { replace: false });
  };
  const heliportsList = useSelector(getHeliportsList);

  const apiPhotos = (list: HeliportsModel[]) => {
    let arrSubs: any[] = [];
    list.forEach((hel: HeliportsModel) => {
      arrSubs.push(from(getPhotosHeliport(hel.city, 1)));
    });
    subs = combineLatest(arrSubs)
      .pipe(
        take(1),
        tap((x) => {
          list = [...list].map((hel, i) => {
            if ((i || i === 0) && x[i]) {
              hel.photo = x[i];
            }
            return hel;
          });
          dispatch(
            setHeliportsList(list.sort((a: any, b: any) => a.city - b.city))
          );
        }),
        catchError((e) => of(e))
      )
      .subscribe();
  };

  useEffect(
    () => () => {
      if (subs) subs.unsubscribe();
    },
    []
  );

  useEffect(() => {
    if (heliportsList.length === 0) {
      getAllHeliports(currentRegion)
        .then((x) => {
          apiPhotos(x);
        })
        .catch((e) => console.error("error ", e));
    } else {
      apiPhotos(heliportsList);
    }
  }, []);

  return (
    <div className="flex w-full bg-primary h-full align-center pt-16 wrap justify-evenly overflow-auto">
      {heliportsList.map((card: HeliportsModel, index: number) => (
        <MediaCard
          sxName={{ color: "#1976d2" }}
          key={index}
          card={card}
          onClick={onClick}
        />
      ))}
    </div>
  );
}
