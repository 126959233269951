import React, { useEffect, useState } from "react";
import MediaCard from "../base/MediaCard";
import CustomAppBar from "./CustomAppBar";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentHeliports, getRegion } from "../../store/root/reducer";
import {
  setCurrentHeliports,
  setHeliportsList,
} from "../../store/heliports/data/action";
import { setPage } from "../../store/appInfo/data/action";
import { useLocation, useNavigate } from "react-router-dom";
import {
  functionCamera,
  getAllHeliports,
  getDetailHeliport,
  getPhotosHeliport,
  saveLog,
} from "../api/api";
import CircleIcon from "@mui/icons-material/Circle";
import moment from "moment";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Switch from "@mui/material/Switch";
import { HeliportsModel } from "../../store/heliports/data/model";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  boxShadow: 24,
};

export default function DashboardPage({ handleLogout }: any) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();
  const eliPoint = process.env.PUBLIC_URL + "/assets/slider.svg";
  const currentHeliport = useSelector(getCurrentHeliports);
  const currentRegion = useSelector(getRegion);
  const [checkedSwitch, setCheckedSwitch] = useState(false);
  const [photo, setPhoto] = useState<any>(null);
  const [map, setMap] = useState<string>("");
  const [gate, setGate] = useState<string>("");
  const [modalSrc, setModalSrc] = useState<string>("");
  const [open, setOpen] = React.useState(false);
  const [intervalPhoto, setIntervalPhoto] = useState<any>(null);

  const handleOpenModal = (type?: string) => {
    switch (type) {
      case "camera":
        setOpen(true);
        setModalSrc(photo);
        break;
      case "map":
        setOpen(true);
        setModalSrc(map);
        break;

      default:
        setOpen(false);
        break;
    }
  };

  const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedSwitch(event.target.checked);

    functionCamera(currentHeliport?.city || "", {
      heliLight: event.target.checked,
    })
      .then()
      .catch((e) => console.error("error ", e));
    if (event.target.checked) {
      saveLog(
        "Accensione luci su securfly " + currentHeliport?.name + " su sito web"
      )
        .then()
        .catch((e) => console.error("error ", e));
    } else if (!event.target.checked) {
      saveLog(
        "Spegnimento luci su securfly " + currentHeliport?.name + " su sito web"
      )
        .then()
        .catch((e) => console.error("error ", e));
    }
  };

  const goTo = (type: string, page: string) => {
    dispatch(setPage("/archive/" + page, type));
    navigate("/archive/" + page, { replace: false });
  };

  const onClose = () => {
    dispatch(setCurrentHeliports(null));
    dispatch(setPage("/home"));
    navigate("/home", { replace: false });
  };

  const onClickLive = () => {
    functionCamera(currentHeliport?.city || "", { camera: true })
      .then()
      .catch((e) => console.error("error ", e));
    saveLog(
      "Accensione camera su securfly " + currentHeliport?.name + " su sito web"
    )
      .then()
      .catch((e) => console.error("error ", e));
  };

  const onClickGate = (type: string) => {
    functionCamera(currentHeliport?.city || "", {
      gate: type === "ON" ? true : false,
    })
      .then()
      .catch((e) => console.error("error ", e));
    saveLog(
      (type === "ON" ? "Apertura" : "Chiusura") +
        " cancello su securfly " +
        currentHeliport?.name +
        " su sito web"
    )
      .then()
      .catch((e) => console.error("error ", e));
  };

  const apiDashboard = (hel: HeliportsModel) => {
    saveLog("Caricata pagina securfly " + hel.name + " su sito web")
      .then()
      .catch((e) => console.error("error ", e));
    setIntervalPhoto(
      setInterval(() => {
        saveLog("Aggiornamento foto su securfly " + hel.name + " su sito web")
          .then()
          .catch((e) => console.error("error ", e));

        getPhotosHeliport(hel.city)
          .then((x) => {
            setPhoto(x);
          })
          .catch((e) => console.error("error ", e));
      }, 3000)
    );

    setMap(process.env.PUBLIC_URL + "/assets/map/" + hel.city + ".png");
    setGate(process.env.PUBLIC_URL + "/assets/helipad/" + hel.city + ".png");
  };

  useEffect(() => () => clearInterval(intervalPhoto), []);

  useEffect(() => {
    if (intervalPhoto) {
      clearInterval(intervalPhoto);
      setIntervalPhoto(null);
    }
    if (currentHeliport) {
      apiDashboard(currentHeliport);
      getDetailHeliport(currentHeliport.city)
        .then((x) => {
          setCheckedSwitch(x.heliLight);
        })
        .catch((e) => console.error("error ", e));
    } else {
      getAllHeliports(currentRegion)
        .then((x) => dispatch(setHeliportsList(x)))
        .catch((e) => console.error("error ", e));
      let arrLoc = location.pathname.split("/");
      if (arrLoc.length === 3) {
        getDetailHeliport(arrLoc[2])
          .then((x) => {
            apiDashboard(x);
            dispatch(setCurrentHeliports(x));
            setCheckedSwitch(x.heliLight);
          })
          .catch((e) => console.error("error ", e));
      }
    }
  }, [currentHeliport]);

  const onClick = (value: any) => {};

  const logout = () => {
    handleLogout();
    navigate("/", { replace: false });
  };

  return !open ? (
    <div className="relative flex flex-col h-full w-full">
      <div className="flex w-full h-1-10 z-1">
        <CustomAppBar handleLogout={logout} />
      </div>
      <div className="relative flex h-9-10 w-full flex-col">
        <div className="flex w-full items-center m-16">
          <CircleIcon
            sx={{
              marginRight: "16px",
              color: currentHeliport?.statusColor,
            }}
          />
          <Typography
            variant="h3"
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              marginBottom: "16px",
              marginTop: "16px",
              width: "100%",
            }}
          >
            Dashboard {currentHeliport?.name}
          </Typography>
          {/* <div
            className="flex justify-end items-center cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
          >
            <CloseIcon />
          </div>*/}
        </div>
        <div className="flex w-full h-full overflow-auto flex-col">
          <div className="flex w-full h-full justify-center items-center">
            <div className="flex h-150 w-415 flex-col justify-center items-center text-center m-10 font-x-large">
              <div className="flex font-bold justify-center">
                {currentHeliport?.coords}
              </div>
              <div className="flex">PREFERRED FINAL APPROACH TAKE-OFF</div>
              <div className="flex text-red font-bold">
                {currentHeliport?.approach}
              </div>
            </div>
          </div>
          <div className="flex w-full h-full align-center wrap justify-evenly font-x-large">
            <div className="flex h-320 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex flex-col h-8-10 relative p-8">
                <img
                  src={photo}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    position: "relative",
                    height: "100%",
                  }}
                  onClick={() => handleOpenModal("camera")}
                />
              </div>
              <div className="relative flex h-2-10 border-top-white items-center w-full text-white">
                <div className="flex font-bold font-medium">Live:&nbsp; </div>
                <button
                  className="cursor-pointer text-white bg-warning"
                  onClick={() => onClickLive()}
                >
                  Live
                </button>
              </div>
            </div>
            <div className="flex h-320 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex flex-col h-8-10 relative p-8">
                <img
                  src={map}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    position: "relative",
                    height: "100%",
                  }}
                  onClick={() => handleOpenModal("map")}
                />
              </div>
              <div className="relative flex h-2-10 border-top-white items-center w-full text-white font-bold font-medium">
                Map
              </div>
            </div>
            <div className="flex h-320 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex flex-col h-8-10 relative">
                {currentHeliport?.city !== "55" &&
                  currentHeliport?.city !== "56" &&
                  currentHeliport?.city !== "57" &&
                  currentHeliport?.city !== "58" && (
                    <img
                      src={gate}
                      style={{
                        position: "relative",
                        height: "100%",
                        zIndex: 1,
                      }}
                    />
                  )}

                {currentHeliport?.city !== "55" &&
                  currentHeliport?.city !== "56" &&
                  currentHeliport?.city !== "57" &&
                  currentHeliport?.city !== "58" && (
                    <div
                      className="flex h-full w-full absolute justify-center"
                      style={{
                        zIndex: 10,
                        transform:
                          "rotate(" + currentHeliport?.windDirection + "deg)",
                      }}
                    >
                      <img
                        src={eliPoint}
                        style={{
                          width: "10%",
                          position: "absolute",
                          height: "12%",
                          zIndex: 10,
                        }}
                      />
                    </div>
                  )}
              </div>
              <div className="relative flex h-2-10 border-top-white items-center w-full text-white">
                <div className="flex font-bold font-medium">
                  Gate Opening (Cancello non collegato):&nbsp;
                </div>
                <button
                  className="cursor-pointer text-white bg-success"
                  onClick={() => onClickGate("ON")}
                >
                  ON
                </button>
                <button
                  className="cursor-pointer text-white bg-error"
                  onClick={() => onClickGate("OFF")}
                >
                  OFF
                </button>
              </div>
            </div>

            <div className="flex h-100 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-2 w-full items-center text-red">
                Air Temperature
              </div>
              <div className="flex h-1-2 w-full items-center text-white justify-end font-bold">
                {currentHeliport?.temperature} °C
              </div>
            </div>
            <div className="flex h-100 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-2 w-full items-center text-red">
                Dew Point
              </div>
              <div className="flex h-1-2 w-full items-center text-white justify-end font-bold">
                {currentHeliport?.dewPoint} °C
              </div>
            </div>
            <div className="flex h-100 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-2 w-full items-center text-red">RH</div>
              <div className="flex h-1-2 w-full items-center text-white justify-end font-bold">
                {currentHeliport?.humidity} %
              </div>
            </div>
            <div className="flex h-100 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-2 w-full items-center text-red">
                Barometric Pressure
              </div>
              <div className="flex h-1-2 w-full items-center text-white justify-end font-bold">
                {currentHeliport?.pressure} mbar
              </div>
            </div>
            <div className="flex h-100 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-2 w-full items-center text-red">
                Vertical Visibility
              </div>
              <div className="flex h-1-2 w-full items-center text-white justify-end font-bold">
                {currentHeliport?.visibility} Mt
              </div>
            </div>
            <div className="flex h-100 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-2 w-full items-center text-red">
                Horizontal Visibility
              </div>
              <div className="flex h-1-2 w-full items-center text-white justify-end font-bold">
                {currentHeliport?.visibilityH} Mt
              </div>
            </div>

            <div className="flex h-150 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-3 w-full items-center text-red">
                Anticatastrophe
              </div>
              <div className="flex h-1-3 w-full items-center text-white justify-end">
                <p className="text-red">SAT BACK-UP SYSTEM</p>
              </div>
              <div className="flex h-1-3 w-full items-center text-white justify-end">
                <p className="text-red"> AVAILABLE:&nbsp;</p>
                <p className="font-bold">NO</p>
              </div>
            </div>
            <div className="flex h-150 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-3 w-full items-center text-red">
                Command
              </div>
              <div className="flex h-1-3 w-full items-center text-white justify-end">
                <p className="text-red">Live Camera:&nbsp;</p>
                <button
                  className="cursor-pointer text-white bg-warning"
                  onClick={() => onClickLive}
                >
                  Live
                </button>
              </div>
              <div className="flex h-1-3 w-full items-center text-white justify-end">
                <p className="text-red">Helipad Lights: </p>{" "}
                <Switch
                  checked={checkedSwitch}
                  onChange={handleChangeSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </div>
            </div>

            <div className="flex h-150 w-415 flex-col justify-center items-center bg-primary p-8 m-10 rounded-2">
              <div className="flex h-1-4 w-full items-center text-red">
                Wind
              </div>
              <div className="flex h-1-4 w-full items-center text-white justify-end">
                <p className="text-red">Wind Direction:&nbsp;</p>
                <p className="font-bold">
                  {Number.parseFloat(
                    currentHeliport?.windDirection || ""
                  ).toFixed(1)}
                </p>
              </div>
              <div className="flex h-1-4 w-full items-center text-white justify-end">
                <p className="text-red">Wind Speed:&nbsp;</p>
                <p className="font-bold">
                  {Number.parseFloat(currentHeliport?.knot || "").toFixed(1)}{" "}
                  Kts
                </p>
              </div>
              <div className="flex h-1-4 w-full items-center text-white justify-end">
                <p className="text-red">Wind Gust:&nbsp;</p>{" "}
                <p className="font-bold">
                  {Number.parseFloat(currentHeliport?.windGust || "").toFixed(
                    1
                  )}{" "}
                  Kts
                </p>
              </div>
            </div>

            <div
              onClick={() =>
                goTo(
                  "Data Archive Detail",
                  "data/" + (currentHeliport ? currentHeliport.city : "")
                )
              }
              className="flex h-50 cursor-pointer w-full flex-col justify-center items-center font-bold p-8 m-10 rounded-2 border border-solid text-blue"
            >
              Data Archive
            </div>
            <div
              onClick={() =>
                goTo(
                  "Photo Detail",
                  "photo/" + (currentHeliport ? currentHeliport.city : "")
                )
              }
              className="flex h-50 cursor-pointer w-full flex-col justify-center items-center font-bold p-8 m-10 rounded-2 border border-solid text-blue"
            >
              Photo Archive
            </div>
            <div className="flex h-100 w-full flex-col justify-center items-center p-8 m-10 rounded-2 border border-solid border-blue">
              <div className="flex font-medium text-red">
                ATTENTION: The data displayed is for advisory purposes only and
                is not to be used for flight planning or operations.
              </div>
              <div className="flex font-bold font-medium">
                Last Data Acquisition:{" "}
                {currentHeliport?.timestamp &&
                moment(currentHeliport?.timestamp).isValid()
                  ? moment(currentHeliport?.timestamp).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Modal open={open} onClose={() => handleOpenModal("")}>
      <Box sx={style}>
        <img
          onClick={() => handleOpenModal("")}
          src={modalSrc}
          style={{
            width: "100%",
            position: "relative",
            height: "100%",
          }}
        />
      </Box>
    </Modal>
  );
}
