export const SET_USERNAME = "APPINFO/DATA/SET_USERNAME";
export const SET_PAGE = "APPINFO/DATA/SET_PAGE";
export const SET_REGION = "APPINFO/DATA/SET_REGION";

export type AppInfoDataState = {
  username: string | null;
  page: { page: string; section?: string | null };
  region: string;
};

interface SetUsernameAction {
  type: typeof SET_USERNAME;
  payload: string | null;
}

interface SetPageAction {
  type: typeof SET_PAGE;
  payload: { page: string; section?: string };
}

interface SetRegionAction {
  type: typeof SET_REGION;
  payload: string;
}

export type AppInfoDataActionTypes =
  | SetUsernameAction
  | SetPageAction
  | SetRegionAction;
