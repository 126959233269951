import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CircleIcon from "@mui/icons-material/Circle";
import { setPage, setRegion } from "../../store/appInfo/data/action";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHeliportsList, getRegion } from "../../store/root/reducer";
import { setCurrentHeliports } from "../../store/heliports/data/action";
import { HeliportsModel } from "../../store/heliports/data/model";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
const arrRegion = [
  {
    label: "Marche",
    photo: process.env.PUBLIC_URL + "/assets/bg-login.jpg",
  },
  {
    label: "Abruzzo",
    photo: process.env.PUBLIC_URL + "/assets/bg-login.jpg",
  },
  {
    label: "Emilia Romagna",
    photo: process.env.PUBLIC_URL + "/assets/bg-login.jpg",
  },
];

const drawerWidth = 200;

export default function PermanentDrawerLeft() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const region = useSelector(getRegion);
  const heliportsList = useSelector(getHeliportsList);

  const onClick = (value: any) => {
    dispatch(setCurrentHeliports(value));
    dispatch(setPage("/dashboard/" + value.city));
    navigate("/dashboard/" + value.city, { replace: false });
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        overflow: "hidden",
        flexShrink: 0,
        zIndex: 0,
        display: "flex",
        flexDirection: "column-reverse",
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          paddingTop: "56px",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List
        sx={{
          height: "100%",
          overflow: "auto",
          backgroundColor: "#222",
          color: "#222",
        }}
      >
        {heliportsList.map((hel: HeliportsModel, index: number, arr: any[]) => (
          <div className="bg-white" key={"heliport" + index}>
            <ListItem
              button
              key={hel._id}
              sx={{ display: "flex", justifyContent: "space-between" }}
              onClick={() => onClick(hel)}
            >
              <ListItemText primary={hel.name} />
              <ListItemIcon sx={{ minWidth: "unset" }}>
                <CircleIcon
                  sx={{
                    color: hel.statusColor,
                  }}
                />
              </ListItemIcon>
            </ListItem>
            {index !== arr.length - 1 && <Divider />}
          </div>
        ))}
      </List>
      {/* <Divider />
      <List
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          backgroundColor: "#FFFFFF",
          color: "#222",
        }}
      >
        {arrRegion.map((text, index, arr) => (
          <div key={"region" + index}>
            <ListItem
              onClick={() => dispatch(setRegion(text.label.toLowerCase()))}
              button
              key={index}
              sx={{
                backgroundColor:
                  region === text.label.toLowerCase() ? "lightgray" : "",
              }}
            >
              <ListItemIcon>
                <MapOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={text.label} />
            </ListItem>
            {index !== arr.length - 1 && <Divider />}
          </div>
        ))}
      </List> */}
    </Drawer>
  );
}
