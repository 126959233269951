import {
  AppInfoDataActionTypes,
  SET_PAGE,
  SET_REGION,
  SET_USERNAME,
} from "./types";

export function setUsername(username: string | null): AppInfoDataActionTypes {
  return {
    type: SET_USERNAME,
    payload: username,
  };
}

export function setPage(
  page: string,
  section?: string
): AppInfoDataActionTypes {
  return {
    type: SET_PAGE,
    payload: { page, section },
  };
}

export function setRegion(region: string): AppInfoDataActionTypes {
  return {
    type: SET_REGION,
    payload: region,
  };
}
