import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";

export default function MediaCard({
  card,
  onClick,
  disableClick,
  sxCard,
  sxImg,
  sxName,
  onClickImage,
}: any) {
  return (
    <Card
      sx={{
        maxHeight: sxCard?.maxHeight ? sxCard.maxHeight : 260,
        minHeight: sxCard?.minHeight ? sxCard.minHeight : 260,
        maxWidth: sxCard?.maxWidth ? sxCard.maxWidth : 500,
        minWidth: sxCard?.minWidth ? sxCard.minWidth : 300,
        width: sxCard?.width ? sxCard.width : 300,
        height: sxCard?.height ? sxCard.height : 200,
        padding: "4px",
        margin: "8px",
      }}
    >
      <CardMedia
        onClick={() => {
          if (onClickImage) {
            onClick(card);
          }
        }}
        component="img"
        sx={{
          cursor: onClickImage ? "pointer" : "default",
          height: sxImg?.height ? sxImg.height : "unset",
        }}
        image={card.photo}
        alt="Photo"
      />
      <CardContent
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          gutterBottom
          variant="subtitle1"
          component="div"
          sx={{
            display: "flex",
            justifyContent: sxName?.justifyContent
              ? sxName.justifyContent
              : "unset",
            width: "100%",
            color: sxName?.color ? sxName.color : "unset",
          }}
        >
          {card.name}
        </Typography>
        {!disableClick && !onClickImage && (
          <CardActions>
            {/* <Button onClick={() => onClick(card)} size="small">
              Vai
            </Button> */}
            <IconButton
              size="large"
              color="primary"
              onClick={() => onClick(card)}
            >
              <ArrowCircleRightRoundedIcon />
            </IconButton>
          </CardActions>
        )}
      </CardContent>
    </Card>
  );
}
