import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { getHeliportsList } from "../../store/root/reducer";
import { useDispatch, useSelector } from "react-redux";
import { setPage } from "../../store/appInfo/data/action";
import BasicMenu from "../base/BasicMenu";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

type Anchor = "top" | "left" | "bottom" | "right";

const archivesButton = [
  { value: "/archive/data", label: "Data Archive" },
  { value: "/archive/photos", label: "Photo Archive" },
];

export default function CustomAppBar({ handleLogout }: any) {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let location = useLocation();

  const heliportsList = useSelector(getHeliportsList);
  const [openDrawer, setOpenDrawer] = useState(false);

  const onChangeRegion = () => {
    dispatch(setPage("/region"));
    navigate("/region", { replace: false });
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    dispatch(setPage(newValue));
    navigate(newValue, { replace: false });
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setOpenDrawer(open);
    };
  return (
    <Box sx={{ flexGrow: 1, height: "100%" }}>
      <AppBar sx={{ backgroundColor: "#222" }} position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawer("left", true)}
          >
            <MenuIcon />
          </IconButton> */}
          <img
            src={process.env.PUBLIC_URL + "/assets/logo_securfly.png"}
            height="28px"
          />
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              color: "#FFFFFF",
              paddingLeft: 2,
              cursor: "pointer",
            }}
            onClick={() => {
              if (location.pathname.includes("home")) {
                window.location.reload();
              } else {
                navigate("/home", { replace: false });
              }
              dispatch(setPage("/home"));
            }}
          >
            Home
          </Typography>

          <Typography
            variant="h6"
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              paddingLeft: "48px",
            }}
          >
            <BasicMenu label={"Heliports"} iconItem elements={heliportsList} />
            <BasicMenu label={"Archive"} elements={archivesButton} />
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              paddingRight: "32px",
            }}
          >
            <Button
              sx={{ textTransform: "capitalize", fontSize: "medium" }}
              color="inherit"
              onClick={onChangeRegion}
            >
              Regioni
            </Button>
          </Typography>
          <LogoutRoundedIcon onClick={handleLogout} />
          <Button
            onClick={handleLogout}
            color="inherit"
            sx={{ textTransform: "capitalize", fontSize: "medium" }}
          >
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      {/* <CustomDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer} /> */}
    </Box>
  );
}
