import React from "react";
import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import AlertTemplate from "react-alert-template-basic";

import {
  positions,
  Provider as AlertProvider,
  transitions,
  types,
} from "react-alert";
import { TailSpin } from "react-loader-spinner";

const options = {
  position: positions.BOTTOM_CENTER,
  type: types.ERROR,
  timeout: 5000,
  offset: "30px",
  transition: transitions.FADE,
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate
        loading={
          <div className="flex bg-primary align-center items-center justify-center w-full h-full">
            <TailSpin color="#FFFFFF" height="100" width="100" />
          </div>
        }
        persistor={persistor}
      >
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
