import React, { useEffect, useState } from "react";
import "./App.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useNavigate,
} from "react-router-dom";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import NotFoundPage from "./components/pages/NotFoundPage";
import { setUsername } from "./store/appInfo/data/action";
import { useDispatch } from "react-redux";
import ArchiveDataPage from "./components/pages/ArchiveDataPage";
import HeliportsPage from "./components/pages/HeliportsPage";
import RegionPage from "./components/pages/RegionPage";
import DashboardPage from "./components/layout/DashboardPage";
import moment from "moment";
import Environment from "./environment";
import { TailSpin } from "react-loader-spinner";
import ArchivePhotoDetailPage from "./components/pages/ArchivePhotoDetailPage";
import ArchiveDataDetailPage from "./components/pages/ArchiveDataDetailPage";
import ArchivePhotoPage from "./components/pages/ArchivePhotoPage";

const ProtectedRoute = ({ redirectPath = "" }: any) => {
  if (!localStorage.getItem("token")) {
    return <Navigate to={redirectPath} replace />;
  } else {
    const jwtParts = localStorage.getItem("token")?.split(".");
    if (jwtParts && jwtParts.length > 1) {
      const payloadInBase64UrlFormat = jwtParts[1];
      const decodedPayload = atob(payloadInBase64UrlFormat);
      const jsonPayload = JSON.parse(decodedPayload);
      let exp = moment.unix(jsonPayload.exp).toISOString();
      if (moment().isAfter(exp)) {
        return <Navigate to={redirectPath} replace />;
      } else {
        return <Outlet />;
      }
    } else {
      return <Navigate to={redirectPath} replace />;
    }
  }
};

function useDynamicEnvironment() {
  const [environment, setEnvironment] = useState(null);
  useEffect(() => {
    fetch("/config.json")
      .then((res) => res.json())
      .then((env) => {
        Environment.config = env;
        setEnvironment(env);
      });
  }, []);
  return environment;
}

function App() {
  const dispatch = useDispatch();
  const environment: any = useDynamicEnvironment();
  const [user, setUser] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    dispatch(setUsername(null));
  };

  if (!environment) {
    return (
      <div className="flex bg-primary align-center items-center justify-center w-full h-full">
        <TailSpin color="#FFFFFF" height="100" width="100" />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="region" element={<RegionPage />} />
          <Route
            path="home"
            element={<HomePage handleLogout={handleLogout} />}
          />
          <Route
            path="archive/photo/:idHeliport"
            element={<ArchivePhotoDetailPage handleLogout={handleLogout} />}
          />
          <Route
            path="archive/data/:idHeliport"
            element={<ArchiveDataDetailPage handleLogout={handleLogout} />}
          />
          <Route
            path="archive/photos"
            element={<ArchivePhotoPage handleLogout={handleLogout} />}
          />
          <Route
            path="archive/data"
            element={<ArchiveDataPage handleLogout={handleLogout} />}
          />
          <Route
            path="heliports"
            element={<HeliportsPage handleLogout={handleLogout} />}
          />
          <Route
            path="dashboard/:idHeliport"
            element={<DashboardPage handleLogout={handleLogout} />}
          />
        </Route>
        {/* <Route path="" element={<LoginPage setUser={setUser} />} /> */}
        <Route index element={<LoginPage setUser={setUser} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
}

export default App;
