import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HeliportsModel } from "../../store/heliports/data/model";
import { getCurrentHeliports, getRegion } from "../../store/root/reducer";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  getAllHeliports,
  getAnalyticsHeliport,
  getDetailHeliport,
} from "../api/api";
import { useLocation } from "react-router-dom";
import {
  setCurrentHeliports,
  setHeliportsList,
} from "../../store/heliports/data/action";
import { CirclesWithBar } from "react-loader-spinner";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const optionsGeneral = {
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "",
    },
  },
  scales: {
    x: {
      title: {
        color: "white",
        display: true,
        text: "Time",

        font: { size: 18, weight: "bold" },
      },
    },
    y: {
      title: {
        color: "white",
        display: true,
        text: "°C",
        font: { size: 18, weight: "bold" },
      },
    },
  },
};
const optionsTemperature = {
  ...optionsGeneral,
  plugins: {
    title: {
      ...optionsGeneral.plugins.title,
      text: "Last 3 hours of temperature in °C",
    },
  },
};
const optionsRh = {
  ...optionsGeneral,
  plugins: {
    title: {
      ...optionsGeneral.plugins.title,
      text: "Last 3 hours of RH in %",
    },
  },
  scales: {
    ...optionsGeneral.scales,
    y: {
      title: {
        ...optionsGeneral.scales.y.title,
        text: "%",
      },
    },
  },
};
const optionsDewPoint = {
  ...optionsGeneral,
  plugins: {
    title: {
      display: true,
      text: "Last 3 hours of dew point in °C",
    },
  },
};
const optionsWind = {
  ...optionsGeneral,
  plugins: {
    title: {
      ...optionsGeneral.plugins.title,
      text: "Last 3 hours of wind in Knots",
    },
  },
  scales: {
    ...optionsGeneral.scales,
    y: {
      title: {
        ...optionsGeneral.scales.y.title,
        text: "Knots",
      },
    },
  },
};
const optionsVisibility = {
  ...optionsGeneral,
  plugins: {
    title: {
      ...optionsGeneral.plugins.title,
      text: "Last 3 hours of vertical visibility in Meters",
    },
  },
  scales: {
    ...optionsGeneral.scales,
    y: {
      title: {
        ...optionsGeneral.scales.y.title,
        text: "Meters",
      },
    },
  },
};
const optionsVisibilityh = {
  ...optionsGeneral,
  plugins: {
    title: {
      ...optionsGeneral.plugins.title,
      text: "Last 3 hours of horizontal visibility in Meters",
    },
  },
  scales: {
    ...optionsGeneral.scales,
    y: {
      title: {
        ...optionsGeneral.scales.y.title,
        text: "Meters",
      },
    },
  },
};
const optionsPressure = {
  ...optionsGeneral,
  plugins: {
    title: {
      ...optionsGeneral.plugins.title,
      text: "Last 3 hours of pressure in Hp",
    },
  },
  scales: {
    ...optionsGeneral.scales,
    y: {
      title: {
        ...optionsGeneral.scales.y.title,
        text: "Hp",
      },
    },
  },
};

export default function DataDetailPage() {
  let dispatch = useDispatch();
  let location = useLocation();
  const [dataTemperature, setDataTemperature] = useState<any>(null);
  const [dataHumidity, setDataHumidity] = useState<any>(null);
  const [dataDewpoint, setDataDewpoint] = useState<any>(null);
  const [dataVisibility, setDataVisibility] = useState<any>(null);
  const [dataWind, setDataWind] = useState<any>(null);
  const [dataPressure, setDataPressure] = useState<any>(null);
  const [dataVisibilityh, setDataVisibilityh] = useState<any>(null);
  const [loaderTemperature, setLoaderTemperature] = useState<boolean>(true);
  const [loaderHumidity, setLoaderHumidity] = useState<boolean>(true);
  const [loaderDewpoint, setLoaderDewpoint] = useState<boolean>(true);
  const [loaderWind, setLoaderWind] = useState<boolean>(true);
  const [loaderVisibility, setLoaderVisibility] = useState<boolean>(true);
  const [loaderVisibilityh, setLoaderVisibilityh] = useState<boolean>(true);
  const [loaderPressure, setLoaderPressure] = useState<boolean>(true);
  const heliportCurrent = useSelector(getCurrentHeliports);
  const currentRegion = useSelector(getRegion);

  const apiChart = (city: string) => {
    getAnalyticsHeliport("temperature", city, "Temperature")
      .then((x) => {
        setDataTemperature(x);
        setLoaderTemperature(false);
      })
      .catch((e) => {
        setLoaderTemperature(false);
        console.error("error ", e);
      });
    getAnalyticsHeliport("humidity", city, "Humidity")
      .then((x) => {
        setDataHumidity(x);
        setLoaderHumidity(false);
      })
      .catch((e) => {
        console.error("error ", e);
        setLoaderHumidity(false);
      });
    getAnalyticsHeliport("dewpoint", city, "Dew point")
      .then((x) => {
        setDataDewpoint(x);
        setLoaderDewpoint(false);
      })
      .catch((e) => {
        console.error("error ", e);
        setLoaderDewpoint(false);
      });
    getAnalyticsHeliport("visibility", city, "Vertical visibility")
      .then((x) => {
        setDataVisibility(x);
        setLoaderVisibility(false);
      })
      .catch((e) => {
        console.error("error ", e);
        setLoaderVisibility(false);
      });
    getAnalyticsHeliport("wind", city, "Wind")
      .then((x) => {
        setDataWind(x);
        setLoaderWind(false);
      })
      .catch((e) => {
        console.error("error ", e);
        setLoaderWind(false);
      });
    getAnalyticsHeliport("pressure", city, "Pressure")
      .then((x) => {
        setDataPressure(x);
        setLoaderPressure(false);
      })
      .catch((e) => {
        console.error("error ", e);
        setLoaderPressure(false);
      });
    getAnalyticsHeliport("visibilityh", city, "Horizontal visibility")
      .then((x) => {
        setDataVisibilityh(x);
        setLoaderVisibilityh(false);
      })
      .catch((e) => {
        console.error("error ", e);
        setLoaderVisibilityh(false);
      });
  };

  useEffect(() => {
    if (heliportCurrent) {
      apiChart(heliportCurrent.city);
    } else {
      getAllHeliports(currentRegion)
        .then((x) => dispatch(setHeliportsList(x)))
        .catch((e) => console.error("error ", e));
      let arrLoc = location.pathname.split("/");
      if (arrLoc.length === 4) {
        getDetailHeliport(arrLoc[3])
          .then((x) => {
            dispatch(setCurrentHeliports(x));
          })
          .catch((e) => console.error("error ", e));
        apiChart(arrLoc[3]);
      }
    }
  }, []);

  return (
    <div className="relative flex bg-primary w-full h-full align-center pt-16 flex-col overflow-y-auto overflow-x-hidden">
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        Temperature (°C)
      </Typography>
      <div className="p-16 relative">
        {dataTemperature && !loaderTemperature ? (
          <Line options={optionsTemperature} data={dataTemperature} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        RH (%)
      </Typography>
      <div className="p-16 relative">
        {dataHumidity && !loaderHumidity ? (
          <Line options={optionsRh} data={dataHumidity} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        Dew Point (°C)
      </Typography>
      <div className="p-16 relative">
        {dataDewpoint && !loaderDewpoint ? (
          <Line options={optionsDewPoint} data={dataDewpoint} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        Wind Knot (Kts)
      </Typography>
      <div className="p-16 relative">
        {dataWind && !loaderWind ? (
          <Line options={optionsWind} data={dataWind} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        Vertical Visibility (Mt)
      </Typography>
      <div className="p-16 relative">
        {dataVisibility && !loaderVisibility ? (
          <Line options={optionsVisibility} data={dataVisibility} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        Horizontal Visibility (Mt)
      </Typography>
      <div className="p-16 relative">
        {dataVisibilityh && !loaderVisibilityh ? (
          <Line options={optionsVisibilityh} data={dataVisibilityh} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
      <Typography
        variant="h4"
        component="div"
        sx={{
          paddingLeft: "16px",
          color: "white",
        }}
        className="bg-primary flex align-center"
      >
        Pressure (hP)
      </Typography>
      <div className="p-16 relative">
        {dataPressure && !loaderPressure ? (
          <Line options={optionsPressure} data={dataPressure} />
        ) : (
          <CirclesWithBar color="#FFFFFF" height="100" width="100" />
        )}
      </div>
    </div>
  );
}
