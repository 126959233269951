import React from "react";

export default function NotFoundPage() {
  return (
    <div className="flex h-full w-full justify-center items-center">
      <img
        src={process.env.PUBLIC_URL + "/assets/404.jpg"}
        style={{ height: "70%" }}
      />
    </div>
  );
}
